.notif-list-item:hover {
  cursor: pointer;
}

.ant-comment-inner {
  padding: 0 !important;
}

.ant-notification-notice-description,
.ant-notification-notice-message {
  margin-inline-start: 44px !important;
}
.ant-notification-notice-message {
  font-weight: 600 !important;
}

/*
 *  SCROLL BAR STYLE
 */

#thin-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#thin-scroll-bar::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#thin-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #555555;
}
