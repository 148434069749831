.root-layout {
  height: 100vh;
}

body,
html,
.ql-container {
  margin: 0;
  font-size: 12px;
  font-family: 'Lato', sans-serif !important;
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Lato', sans-serif !important;
    }
  }

  --primary-color: #6d1d4f;
  --primary-color-2: #ffd248;
  --primary-color-3: #1c0814;
  --secondary-color: #4ab4a2;
  --secondary-color-2: #fb9a61;
  --secondary-color-3: #eac3dc;
  --secondary-color-4: #a6abb8;
  --secondary-color-5: #004c14;
  --secondary-color-6: #f56a00;
  --secondary-color-7: #594edd;
}

.signin-with-google-button {
  background-color: #bae7ff !important;
  border-radius: 4px !important;
  color: black !important;
  padding: 0px 30px 0px 0px !important;
}

.ant-anchor::before {
  display: none;
}

.ant-anchor-link,
.ant-anchor-link-title {
  padding: 0 !important;
  color: var(--primary-color) !important;
}

.ant-card-actions {
  background: transparent;
}

/* Hide the scrollbar for all elements */
*::-webkit-scrollbar {
  display: none !important;
}

/* Style the scrollbar for the * element */
*::-webkit-scrollbar {
  display: block !important; /* Enable the scrollbar for the * element */
  width: 6px !important; /* Set the width of the scrollbar track */
  height: 6px !important; /* Set the height of the scrollbar thumb */
  background-color: transparent !important; /* Set the background color of the scrollbar track */
  border-radius: 4px !important; /* Set the border radius of the scrollbar thumb */
  cursor: pointer !important; /* Change the cursor on hover */
}

/* Style the scrollbar thumb */
*::-webkit-scrollbar-thumb {
  background-color: var(
    --border
  ) !important; /* Set the background color of the scrollbar thumb */
  border-radius: 4px !important; /* Set the border radius of the scrollbar thumb */
  cursor: pointer !important; /* Change the cursor on hover */
}

/* Change the scrollbar thumb color on hover */
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--border) !important;
  cursor: pointer !important; /* Set the background color of the scrollbar thumb on hover */
}

.ant-select-selection-search-input {
  font-family: 'Lato', sans-serif !important;
}

.ant-form-item-extra {
  font-size: 0.9rem !important;
}

div.ant-typography-edit-content {
  left: unset;
  margin-top: unset;
  margin-bottom: unset;
}

div.ant-typography-edit-content > textarea {
  font-weight: 500;
}

.ag-grid-custom-cell-span,
.ant-input-search {
  background: #fff;
}

.ant-radio-wrapper {
  align-items: center;
}
.ant-radio {
  top: unset;
}

.commandbar-launcher {
  left: 5px !important;
  bottom: 40px !important;
  right: unset !important;
}

//react-quil heading custom css override for Text block
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: flex;
  align-items: baseline;
  column-gap: 2rem;
  padding: 0 1rem;
  margin-top: 5px;
}

.scrollable-tabs-container .ant-tabs,
.scrollable-tabs-container .ant-tabs-content-holder,
.scrollable-tabs-container .ant-tabs-content,
.scrollable-tabs-container .ant-tabs-tabpane {
  height: 100% !important;
  overflow-y: hidden;
}
