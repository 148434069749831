.error-block .ant-result {
  padding: 0;
}

.error-block .ant-result-icon {
  margin-bottom: 8px;
}

.error-block .ant-result-icon > .anticon {
  font-size: 24px;
}

.error-block .ant-result-title {
  font-size: 20px;
}
